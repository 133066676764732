<template>
  <b-row class="match-height">
    <b-col>
      <StaticCard
        variant="light-secondary"
        icon="AlertTriangleIcon"
        title="Análise"
        :value="statisticData.analise"
      />
    </b-col>

    <b-col>
      <StaticCard
        variant="light-success"
        icon="DollarSignIcon"
        title="Aprovado"
        :value="statisticData.aprovado"
      />
    </b-col>

    <b-col>
      <StaticCard
        variant="light-danger"
        icon="BoxIcon"
        title="Negado"
        :value="statisticData.negado"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'

import StaticCard from './StaticCard.vue'

export default {
  components: {
    BRow,
    BCol,
    StaticCard,
  },
  computed: {
    ...mapState({
      items: state => state.seguradora.filteredOrcamentos,
    }),
    ...mapGetters({
      statisticData: 'seguradora/orcamentoStatistics',
    }),
  },
}
</script>
