<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <card-totais :items="items" />
      </b-col>
      <b-col
        md="8"
      >
        <statistics-direcionador />
      </b-col>
    </b-row>
    <section>
      <card-list-orcamento
        :items="items"
        :update-grid="getListOrcamentos"
      />
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CardTotais from './cards/CardTotaisSeguradora.vue'
import statisticsDirecionador from './cards/CardStatisticsSeguradora.vue'
import CardListOrcamento from './cards/CardListOrcamentoSeguradora.vue'

export default {
  components: {
    BRow,
    BCol,
    statisticsDirecionador,
    CardTotais,
    CardListOrcamento,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-info',
          title: 'R$ 2.645,99',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: 'R$ 2.645,99',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: '15 Dias',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '35%',
          subtitle: 'PT',
          customClass: '',
        },
      ],
      items: [],
    }
  },
  created() {
    this.getListOrcamentos()
  },
  methods: {
    getListOrcamentos() {
      this.$store.dispatch('seguradora/getOrcamentos')
    },
  },
}
</script>
