<template>
  <b-card class="justify-items-center align-items-center">
    <b-media no-body>
      <b-row class="d-flex flex-column">
        <b-col class="text-center my-1">
          <b-avatar
            size="48"
            :variant="variant"
          >
            <feather-icon
              size="24"
              :icon="icon"
            />
          </b-avatar>
        </b-col>
        <b-col class="text-center">
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0">
              {{ title }}
            </h4>
            <b-card-text class="mt-1">
              <h5>{{ value }}</h5>
            </b-card-text>
          </b-media-body>
        </b-col>
      </b-row>
    </b-media>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BMedia, BMediaBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaBody,
    BAvatar,
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
}
</script>
