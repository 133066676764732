<template>
  <b-card>
    <b-row class="mb-2">
      <b-col
        md="2"
      >
        <label>Status</label>
        <v-select
          v-model="statusFilter"
          :options="optionsStatus"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Tipo de Atendimento</label>
        <v-select
          v-model="tipoAtendimentoFilter"
          :options="optionsTipoAtendimento"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Dias em Aberto</label>
        <v-select
          v-model="QDAFilter"
          :options="optionsQDAFilter"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Marca</label>
        <v-select
          v-model="marcaFilter"
          :options="optionsMarca"
          multiple
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Table Top -->
        <b-row
          class="mb-2"
        >
          <!-- Search -->
          <b-col
            md="1"
          >
            <b-form-select
              v-model="perPage"
              label="text"
              :options="[10, 25, 50, 100]"
              size="sm"
            />
          </b-col>
          <b-col
            md="10"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="listFiltered"
            :fields="fields"
            :filter-included-fields="filterOn"
          >
            <template #cell(status)="data">
              <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(data_retorno_orcamento)="data">
              {{ resolveDataAprovacao(data.item) }}
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at ? dataHora(data.item.created_at, true) : null }}
            </template>

            <template #cell(valor_total_aprovado)="data">
              {{ data.item.valor_total_aprovado ? `R$ ${valorBr(data.item.valor_total_aprovado, true)}` : null }}
            </template>
            <template #cell(acoes)="data">
              <b-row>
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.top="'Detalhes'"
                    class="color-icon"
                    size="16"
                    icon="EyeIcon"
                    @click="openModalOrcamento(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-detalhe-orcamento"
      ref="showModalOrcamento"
      size="xl"
      :title="`Orcamento ${infoOrcamento.orcamento_id}`"
      @hidden="clearAcoes()"
    >
      <b-tabs>
        <b-tab v-if="visualizarDadosModal">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Orcamento</span>
          </template>

          <b-row class="mb-1">
            <b-col md="2">
              <label><strong>Sinistro</strong></label>
              <b-form-input
                :value="infoOrcamento.sinistro"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Data Abertura</strong></label>
              <b-form-input
                :value="infoOrcamento.data_abertura ? formatTimezone(infoOrcamento.data_abertura, true) : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Status</strong></label>
              <b-form-input
                :value="infoOrcamento.status"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Valor Total Orcamento</strong></label>
              <money
                :value="infoOrcamento.valor_total_orcamento"
                v-bind="money"
                class="form-control"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Valor Aprovado no Sistema</strong></label>
              <money
                :value="infoOrcamento.valor_total_aprovado"
                v-bind="money"
                class="form-control"
                readonly
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Tipo Orçamento</strong></label>
              <b-form-input
                :value="infoOrcamento.tipo_orcamento"
                disabled
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <label><strong>Data Criação</strong></label>
              <b-form-input
                :value="infoOrcamento.data_criacao ? formatTimezone(infoOrcamento.data_criacao, true) : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label><strong>Data Auditação</strong></label>
              <b-form-input
                :value="infoOrcamento.data_auditacao ? formatTimezone(infoOrcamento.data_auditacao, true) : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label><strong>Data Envio Orçamento</strong></label>
              <b-form-input
                :value="infoOrcamento.data_envio_orcamento ? formatTimezone(infoOrcamento.data_envio_orcamento, true) : ''"
                disabled
              />
            </b-col>
            <b-col>
              <label><strong>Status Retorno</strong></label>
              <b-form-input
                :value="infoOrcamento.status_retorno"
                disabled
              />
            </b-col>
            <b-col>
              <label><strong>Valor Total Seguradora</strong></label>
              <money
                :value="infoOrcamento.valor_total_seguradora"
                v-bind="money"
                class="form-control"
                readonly
              />
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <label><strong>Retorno Orçamento</strong></label>
                  <b-form-input
                    :value="infoOrcamento.data_retorno_orcamento ? formatTimezone(infoOrcamento.data_retorno_orcamento, true) : ''"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr>
          <b-row
            v-for="(dados, index) in infoOrcamento.servicos"
            :key="index"
            class="mb-1 match-height"
          >
            <b-col md="1">
              <label for=""><strong>Tipo Servico</strong></label>
              <b-form-input
                :value="dados.tipo_servico"
                disabled
              />
            </b-col>
            <b-col md="4">
              <label for=""><strong>Descrição</strong></label>
              <b-form-input
                :value="dados.descricao"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Observação</strong></label>
              <b-row>
                <b-col>
                  <b-form-input
                    :value="dados.observacao"
                    disabled
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1">
              <label for=""><strong>Quant.</strong></label>
              <b-form-input
                :value="dados.quantidade"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>Valor Solicitado</strong></label>
              <div class="d-flex">
                <money
                  :value="dados.valor_solicitado"
                  v-bind="money"
                  class="form-control"
                  readonly
                />
              </div>
            </b-col>
            <b-col md="2">
              <label for=""><strong>Valor Aprovado</strong></label>
              <b-row>
                <b-col>
                  <money
                    :value="dados.valor_aprovado"
                    v-bind="money"
                    class="form-control"
                    readonly
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-tab>
        <b-tab v-if="visualizarDadosModal">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Prestador de Serviço</span>
          </template>

          <b-row class="mb-1">
            <b-col md="2">
              <label><strong>NOME</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CPF_CNPJ</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CHAVE PIX</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>BANCO</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>AGENCIA</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>CONTA</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>TELEFONE</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
                disabled
              />
            </b-col>
            <b-col md="2">
              <label for=""><strong>EMAIL</strong></label>
              <b-form-input
                :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
                disabled
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{}">
        <b-button
          v-show="infoOrcamento.status === 'AGUARDANDO ANÁLISE'"
          class="mr-1"
          size="md"
          variant="outline-success"
          @click="aprovarOrcamento(infoOrcamento)"
        >
          Aprovar
          <feather-icon
            class="color-icon"
            size="16"
            icon="ThumbsUpIcon"
          />
        </b-button>

        <b-button
          v-show="infoOrcamento.status === 'AGUARDANDO ANÁLISE'"
          class="mr-1"
          size="md"
          variant="outline-danger"
          @click="negarOrcamento(infoOrcamento)"
        >
          Negar
          <feather-icon
            class="color-icon"
            size="16"
            icon="ThumbsDownIcon"
          />
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          @click="toViewOs(infoOrcamento.ordem_de_servico_id)"
        >
          Ir Para OS
          <feather-icon
            class="color-icon"
            size="16"
            icon="ExternalLinkIcon"
          />
        </b-button>
      </template>
    </b-modal>
  </b-card>

</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BModal, BBadge, VBTooltip, BFormInput, BCard, BTabs, BTab, BButton, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import { Money } from 'v-money'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BModal,
    BBadge,
    BFormInput,
    BCard,
    BTabs,
    BButton,
    BTab,
    vSelect,
    BFormSelect,
    BOverlay,
    Money,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    updateGrid: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      searchQuery: null,
      endpoint: process.env.VUE_APP_ROOT_API,
      nome_arquivo: null,
      perPage: 50,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
        { key: 'created_at', label: 'Data', sortable: true, class: 'text-center' },
        { key: 'dias_vencidos',
          label: 'QDA',
          class: 'text-center',
          sortable: true,
          formatter(value, key, item) {
            const dataAtual = new Date()
            const dataAbertura = new Date(item.data_abertura)
            const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
            return diferencaEmDias < 0 ? 'Em andamento' : `${diferencaEmDias}`
          },
        },
        { key: 'tipo_atendimento', label: 'Tipo de Atendimento', sortable: true },
        { key: 'sinistro', label: 'Sinistro', sortable: true, class: 'text-center' },
        { key: 'seguradora', label: 'SEGURADORA', sortable: true, class: 'text-center' },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado', sortable: true },
        { key: 'data_retorno_orcamento', label: 'Data Aprovação', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'text-center' },
      ],
      orcamento_id: null,
      infoOrcamento: {},
      visualizarDadosModal: false,

      valorTotalSeguradora: '',
      dataRetornoOrcamento: '',
      statusRetorno: '',
      statusFilter: [],
      atendenteFilter: [],
      seguradoraFilter: [],
      marcaFilter: [],
      tipoAtendimentoFilter: [],
      load: false,
    }
  },
  computed: {
    ...mapState({
      items: state => state.seguradora.orcamentos,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    optionsStatus() {
      return [...new Set(this.items.map(item => item.status))]
    },
    optionsSeguradora() {
      const uniqueSeguradoras = new Set()
      this.items.forEach(item => {
        if (item.seguradora) {
          uniqueSeguradoras.add(item.seguradora)
        }
      })
      return [...uniqueSeguradoras]
    },
    optionsMarca() {
      const uniqueMarca = new Set()
      this.items.forEach(item => {
        if (item.marca) {
          uniqueMarca.add(item.marca)
        }
      })
      return [...uniqueMarca]
    },
    optionsAtendenteFilter() {
      return [...new Set(this.items.map(item => item.atendente))]
    },
    optionsTipoAtendimento() {
      return [...new Set(this.items.map(item => item.tipo_atendimento))]
    },
    listFiltered() {
      const { items, statusFilter: sF, tipoAtendimentoFilter: tF, marcaFilter: mF, searchQuery } = this
      let list = items

      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }

      if (tF.length > 0) {
        list = list.filter(item => tF.includes(item.tipo_atendimento))
      }
      if (mF.length > 0) {
        list = list.filter(item => {
          if (item.marca) {
            return mF.includes(item.marca)
          }
          return false
        })
      }

      if (searchQuery && searchQuery.length > 0) {
        list = list.filter(item => item.sinistro.includes(searchQuery))
      }

      this.$store.commit('seguradora/SET_FILTERED_ORCAMENTOS', list)

      this.totalRows = list.length
      return list
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openModalOrcamento(item) {
      this.infoOrcamento = item
      this.visualizarDadosModal = true
      this.$refs.showModalOrcamento.show()
    },
    toViewOs(id) {
      this.$router.push({ name: 'seguradora-os-view', params: { id } })
    },
    clearAcoes() {
      this.dataEnvioOrcamento = ''
      this.dataRetornoOrcamento = ''
      this.valorTotalSeguradora = ''
      this.statusRetorno = ''
    },
    retornoOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja confirmar o retorno desse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.load = true
          const body = {
            orcamento_id: this.infoOrcamento.orcamento_id,
            data_retorno: this.dataRetornoOrcamento,
            status_retorno: this.statusRetorno.toUpperCase(),
          }
          this.valorTotalSeguradora.length ? body.valor_retorno = this.valorDataBase(this.valorTotalSeguradora) : null
          axios
            .post('api/orcamento/retorno', body)
            .then(() => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              this.load = false
              this.$swal({
                title: 'SUCESSO!',
                icon: 'success',
                text: 'RETORNO CONFIRMADO!',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(error => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              this.load = false
              if (error.response) {
                this.$swal({
                  title: 'ERRO!',
                  icon: 'error',
                  text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                  customClass: {
                    confimButton: 'btn btn-danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveDataAprovacao(item) {
      if (item.status_retorno === 'APROVADO') {
        return item.data_retorno_orcamento ? this.formatTimezone(item.data_retorno_orcamento) : ''
      }
      return ''
    },

    async aprovarOrcamento(item) {
      const body = {
        orcamento_id: item.orcamento_id,
        status_retorno: 'APROVADO',
        valor_total_seguradora: 600,
      }

      const { error } = await this.$store.dispatch('os/aprovarOrcamento', body)

      // handle error
      if (error) {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro:',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })

        return null
      }
      this.updateGrid()

      this.$swal({
        title: 'SUCESSO!',
        icon: 'success',
        text: 'RETORNO CONFIRMADO!',
        timer: 1500,
        showConfirmButton: false,
      })

      return null
    },

    async negarOrcamento(item) {
      const body = {
        orcamento_id: item.orcamento_id,
        status_retorno: 'NEGADOR',
      }

      const { error } = await this.$store.dispatch('os/negarOrcamento', body)

      // handle error
      if (error) {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'Ocorreu um erro:',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })

        this.updateGrid()

        return null
      }

      this.$swal({
        title: 'SUCESSO!',
        icon: 'success',
        text: 'RETORNO CONFIRMADO!',
        timer: 1500,
        showConfirmButton: false,
      })

      return null
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
.per-page-selector {
  width: 90px;
}
</style>
